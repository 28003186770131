import Header from ".././components/header";
import Reveal from "react-reveal/Reveal";

export default function Experience() {
  return (
    <div>
      <Header large={false} />
      <hr />
      <h3 className="pageHeader text-center display-4">My Experience</h3>
      <div className="container d-flex justify-content-around flex-wrap">
        <Reveal>
          <div className="card snippets">
            <div className="card-header h3 text-center">
              <div className="">Where I've Worked</div>
            </div>
            <div className="card-body">
              {" "}
              <h5 className="text-center">
                Safety Research Corporation of America
              </h5>
              <h6 className="text-center upfix">Junior Software Engineer</h6>
              <h6 className="text-center upfix" style={{ fontSize: "0.8em" }}>
                May 2021 - Present
              </h6>
              <hr />
              <p className="text-left">
                Starting in May of 2021, I joined a team in Southeast Alabama
                that develops high quality and robust software for commercial
                and government clients. There are a wide variety of tools used
                in my day-to-day. A non-extensive list includes...
                <ul className="text-left">
                  <li>
                    <strong>.NET 6 framework</strong> for web applications
                  </li>
                  <li>
                    <strong>Git</strong> for source version controls
                  </li>
                  <li>
                    <strong>MySQL, PostgreSQL</strong> for database
                  </li>
                  <li>
                    <strong>Skype</strong> for team communication
                  </li>
                </ul>
              </p>
              <div className="seperator">&#8226; &#8226; &#8226;</div>
              <h5 className="text-center">RT Systems</h5>
              <h6 className="text-center upfix">Programmer</h6>
              <h6 className="text-center upfix" style={{ fontSize: "0.8em" }}>
                July 2020 - May 2021
              </h6>
              <hr />
              <p className="text-left">
                My main tasks include developing and maintaining programming
                software intended for amateur radios using C/C++ and Java. In
                addition to this, I also work with porting software from Windows
                to Mac, as well as designing the setting windows to be as user
                friendly as possible.
              </p>
              <p className="text-left">
                The tools that I use at RT Systems on a daily basis include:
                <ul className="text-left">
                  <li>
                    <strong>C/C++ & Java</strong> for software development
                  </li>
                  <li>
                    <strong>Microsoft Visual Studio & IntelliJ</strong> is the
                    IDE software for Windows and Mac, respectively
                  </li>
                  <li>
                    <strong>Subversion</strong> for source version control
                  </li>
                  <li>
                    <strong>Microsoft Teams</strong> for team communication
                  </li>
                </ul>
              </p>
            </div>{" "}
          </div>
        </Reveal>
        <Reveal>
          <div className="card snippets" style={{ height: "100%" }}>
            <div className="card-header h3 text-center">
              <div className="">Where I've Studied</div>
            </div>
            <div className="card-body">
              {" "}
              <h5 className="text-center">Auburn University</h5>
              <h6 className="text-center upfix">
                Bachelor of Software Engineering
              </h6>
              <hr />
              <p className="text-left">
                Attended Auburn University from 2016 until completion in 2019. I
                really enjoyed my time at Auburn, and the core course work
                provided me a solid foundation of software engineering
                principles. Along with my required courses I also took electives
                in Artificial Intelligence, Video Game Design, and Database.
              </p>
              <div className="seperator">&#8226; &#8226; &#8226;</div>
              <h5 className="text-center">Wallace Community College</h5>
              <h6 className="text-center upfix">Pre-engineering courses</h6>
              <hr />
              <p className="text-left">
                Prior to starting my time at Auburn University, I attended a
                community college in Dothan, Alabama. I took 41 credit hours of
                general education and pre-engineering courses and finished with
                a 4.0 overall GPA.
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className="card snippets">
            <div className="card-header h3 text-center">
              <div className="">Additional Experience</div>
            </div>
            <div className="card-body">
              {" "}
              <h5 className="text-center">Music Matters Booking System</h5>
              <h6 className="text-center upfix">React Native + Firebase</h6>
              <hr />
              <p className="text-left">
                For my senior year at Auburn University, I was on a team with
                three other students to continue development on a React Native
                application. The purpose of this application was to provide our
                client a phone application to suit his needs to easily book
                clients and send out automatic email invoices from his phone
                application.
              </p>
            </div>{" "}
          </div>
        </Reveal>
      </div>
      <hr />
    </div>
  );
}
