import Header from ".././components/header";
import Reveal from "react-reveal/Reveal";
import billy from "../img/billy.jpg";
import quiz from "../img/quiz.jpg";
import { Github } from "react-bootstrap-icons";

export default function Projects() {
  return (
    <div>
      <Header large={false} />
      <hr />
      <h3 className="pageHeader text-center display-4">
        Projects & Experience
      </h3>
      <div className="container d-flex justify-content-around flex-wrap">
        <Reveal>
          <div className="card snippets ">
            {" "}
            <div className="card-header">
              <span
                className="h3 text-center"
                href="http://billy.samuelskipper.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Software Portal
              </span>
            </div>
            <div className="card-body buttonRoom">
              <h5 className="text-center">
                Software and Alert delivery system
              </h5>
              <h6 className="text-center upfix">.NET 6 + PostgreSQL</h6>
              <hr />
              <p className="text-left proj-media">
                During my time at SRCA, I was part of a team developing a web
                application in a series of sprints using an Agile development
                process, from concept to delivery. Software Portal hosts a large
                number of important announcements and software updates intended
                for military personnel.{" "}
              </p>{" "}
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className="card snippets ">
            {" "}
            <div className="card-header">
              <a
                className="h3 text-center"
                href="http://billy.samuelskipper.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Billy
              </a>
            </div>
            <div className="card-body buttonRoom">
              <h5 className="text-center">Bill reminder payment application</h5>
              <h6 className="text-center upfix">React + Redux</h6>
              <img
                className="border rounded"
                src={billy}
                alt="billy"
                style={{ width: 50 + "%" }}
              />
              <hr />
              <p className="text-left proj-media">
                Bill payment reminder app that demonstrates knowledge of React
                and Redux. After filling out the form for a specific bill, the
                payment information is then displayed at the bottom of the
                screen.{" "}
              </p>{" "}
              <p className="text-right">
                <a
                  className="btn btn-light git"
                  href="https://github.com/samoskip/billy-reminder"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: 0.75 + "em",
                    position: "absolute",
                    bottom: 0,
                    right: 0
                  }}
                >
                  {"   "}
                  <Github size={32} color="grey" /> GitHub Repository
                </a>
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className="card snippets">
            <div className="card-header">
              {" "}
              <a
                className="h3 text-center"
                href="http://quiz.samuelskipper.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Quiz
              </a>
            </div>
            <div className="card-body buttonRoom">
              {" "}
              <h5 className="text-center">Quiz video game </h5>
              <h6 className="text-center upfix">React + Redux Thunk</h6>
              <img
                className="border rounded"
                src={quiz}
                alt="quiz"
                style={{ width: 50 + "%" }}
              />
              <hr />
              <p className="text-left proj-media">
                An interactive quiz game that uses an API call with Redux Thunk
                to a trivia database. The winning condition is to answer ten
                questions correctly in a row. If the user selection the wrong
                answer, they are given a failure screen. After a few seconds,
                the application will reset with a new set of questions.
              </p>{" "}
              <p className="text-right">
                <a
                  className="btn btn-light git"
                  href="https://github.com/samoskip/quiz-thunk"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: 0.75 + "em",
                    position: "absolute",
                    bottom: 0,
                    right: 0
                  }}
                >
                  {"   "}
                  <Github size={32} color="grey" /> GitHub Repository
                </a>
              </p>
            </div>
          </div>
        </Reveal>
      </div>
      <hr />
    </div>
  );
}
