import React from "react";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
export default function Button() {
  return (
    <div className="">
      <Link className="" to="/contact">
        <button type="button" className="comein btn btnCustom">
          Get in touch <ArrowRightCircleFill className="arrow" size={21} />
        </button>
      </Link>
    </div>
  );
}
