import Header from ".././components/header";
import au from "../img/au.png";
import { Linkedin, Github } from "react-bootstrap-icons";

export default function Home() {
  return (
    <div>
      <Header large={true} />
      <hr />
      <div className="d-flex justify-content-around flex-wrap">
        <div className="homeIcon">
          <img
            className="iconColor "
            src={au}
            alt="Auburn University Icon"
            width={166}
          />
          <h5>Auburn University Alumni</h5>
          <hr />
          <p>
            Bachelor of Engineering in Software Engineering from Auburn
            University. Class of 2019. War Eagle!
          </p>
        </div>

        <div className="homeIcon">
          <a
            href="https://www.linkedin.com/in/samoskip/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin className="iconColor " size={146} color="black" />
          </a>
          <h5>LinkedIn</h5>
          <hr />
          <p>Network with me over on LinkedIn.</p>
        </div>
        <div className="homeIcon">
          <a
            href="https://github.com/samoskip"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Github size={146} className="iconColor " color="black" />
          </a>
          <h5>Github</h5>
          <hr />
          <p>
            My Git Repositories, see what personal projects I've been working
            on.
          </p>
        </div>
      </div>
      <hr />
    </div>
  );
}
