import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/home";
import Contact from "../pages/contact";
import Projects from "../pages/projects";
import Experience from "../pages/experience";

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/projects" component={Projects} />
      <Route path="/experience" component={Experience} />
      <Route path="/contact" component={Contact} />
    </Switch>
  </main>
);

export default Main;
