import Header from ".././components/header";
import Reveal from "react-reveal/Reveal";
import { Linkedin, EnvelopeFill } from "react-bootstrap-icons";
export default function Contact() {
  return (
    <div>
      <Header large={false} />
      <hr />
      <h3 className="pageHeader text-center display-4">Get in touch</h3>
      <div className="container d-flex justify-content-around flex-wrap">
        <Reveal>
          <div className="card snippets">
            {" "}
            <div className="card-header h3 text-center">
              <div className="">Email Me</div>
            </div>
            <div className="card-body">
              {" "}
              <EnvelopeFill className="iconColor " size={108} color="black" />
              <hr />
              <p className="text-left">
                Feel free to email me at{" "}
                <a href="mailto:samueloskipper@gmail.com">
                  samueloskipper@gmail.com
                </a>{" "}
                for professional or personal inquiries. I'll try to get back in
                touch as soon as possible.
              </p>
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className="card snippets">
            {" "}
            <div className="card-header h3 text-center">
              <div className="">Connect on LinkedIn</div>
            </div>
            <div className="card-body">
              {" "}
              <Linkedin className="iconColor " size={108} color="black" />
              <hr />
              <p className="text-left">
                Network with me on my{" "}
                <a
                  href="https://www.linkedin.com/in/samoskip/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>{" "}
                page. I'm always interested in making new connections. If you
                have any quick questions to ask, my LinkedIn is often the
                fastest way for me to give you a response.
              </p>
            </div>
          </div>
        </Reveal>
      </div>
      <hr />
    </div>
  );
}
