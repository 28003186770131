import "./styles.css";
import Footer from "./components/footer";
import NavBar from "./components/navbar";

import Main from "./components/main";
import { useLocation } from "react-router-dom";

function HeaderView() {
  const location = useLocation();
  if (location.pathname === "/") {
    return 0;
  } else if (location.pathname === "/projects") {
    return 1;
  } else if (location.pathname === "/experience") {
    return 2;
  } else if (location.pathname === "/contact") {
    return 3;
  }
  return -1;
}

export default function App() {
  return (
    <div className="App">
      <NavBar page={HeaderView()} />
      <Main />
      <Footer />
    </div>
  );
}
