import React from "react";
import { Link } from "react-router-dom";

export default function NavBar(props) {
  return (
    <div className="">
      <nav className="navbar navbar-expand-lg navbar-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className={props.page === 0 ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className={props.page === 1 ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/projects">
                Projects
              </Link>
            </li>
            <li className={props.page === 2 ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/experience">
                Experience
              </Link>
            </li>
            <li className={props.page === 3 ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/contact">
                Contact Me
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
