import React from "react";
import Button from "./button";
import sam from "../img/samskipper-b.png";
import ReactTypingEffect from "react-typing-effect";
import Fade from "react-reveal/Fade";

export default function Header(props) {
  if (props.large) {
    return (
      <div>
        <div
          className="d-flex justify-content-around flex-wrap header jumbotron text-center"
          style={{
            backgroundImage: "url(" + require("../img/bg.jpg") + ")"
          }}
        >
          <div className="leftJumbo">
            <h1 className="display-4 bigtx">Hello, I'm Samuel Skipper</h1>
            <h1 className="display-5 hilite ">
              <ReactTypingEffect
                speed={65}
                cursor={""}
                typingDelay={250}
                eraseDelay={99999999999}
                text={"[software engineer]"}
              />
            </h1>
            <Button />
          </div>
          <div className="sam">
            <Fade left>
              <img className="shrink" src={sam} alt="Samuel Skipper" />
            </Fade>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          className="header-sm jumbotron text-center"
          style={{
            backgroundImage: "url(" + require("../img/bg.jpg") + ")"
          }}
        >
          <div className="">
            <h1 className="display-4 smtx">Samuel Skipper</h1>
            <h1 className="display-5 hilite ">[software engineer] </h1>
          </div>
          <div className=""></div>
        </div>
      </div>
    );
  }
}
