import React from "react";
import { Linkedin, Github } from "react-bootstrap-icons";

export default function Footer() {
  return (
    <div className="card border-0">
      <div className="card-body footer ">
        <a
          className="btn btnCustom"
          href="https://www.linkedin.com/in/samoskip/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Linkedin className="footIco" size={42} />
        </a>
        <a
          className="btn btnCustom"
          href="https://github.com/samoskip"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Github className="footIco" size={42} />
        </a>
      </div>
    </div>
  );
}
